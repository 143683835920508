body {
  background-color: black;
  color: white;
}

.App {
  text-align: center;
  padding-bottom: 100px; /* Add padding to the bottom to create space for the footer */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: black; /* Changed to black */
  color: white;
  position: relative; /* Ensures it doesn't overlap with other elements */
  z-index: 10; /* Ensures it stays on top */
}

.logo-container {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 1rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

.mobile-nav-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: rgba(12, 0, 0, 0.371);
  padding: 1rem;
  border-radius: 5px;
  z-index: 5000;
}

.mobile-nav a {
  padding: 0.5rem 0;
  color: white;
  text-decoration: none;
}

.mobile-nav a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .mobile-nav {
    display: flex;
  }

  .carousel {
    display: none; /* Hide carousel on small screens */
  }
}

.welcome-container {
  margin-top: 2rem; /* Add margin for spacing */
  padding: 1rem;
}

.welcome-container h1 {
  font-size: 2.5em;
  margin-bottom: 0.5rem;
}

.welcome-container p {
  font-size: 1.2em;
  margin-top: 0;
}

.footer {
  background-color: black;
  color: white;
  padding: 1rem;
  position: relative;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; /* Change to row for horizontal layout */
  gap: 0.5rem; /* Add some space between elements */
  text-align: center;
}

.footer p {
  margin: 0; /* Remove default margins */
}

.footer-logo {
  max-width: 30px; /* Adjust the width as necessary */
  margin: 0 0.5rem; /* Adjust margin for spacing */
}

/* Add more space below the login element */
.login-container {
  margin-bottom: 100px; /* Adjust the value as needed */
}