/* Base styles */
.post {
    display: inline-block;
    margin: 5px;
    width: 300px;
    height: 300px;
    max-width: 300px;
    max-height: 300px;
    position: relative;
    flex: 0 0 auto;
    background-color: black; /* Set background to black */
}

.post img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.posts-container {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: black; /* Set background to black */
}

.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
    z-index: 1000;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.posts-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.posts-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.img-container {
    position: relative;
    height: 0;
    padding-bottom: 86.66%;
    overflow: hidden;
}

.img-container img {
    -webkit-clip-path: polygon(25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%, 0% 50%);
    clip-path: polygon(25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%, 0% 50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.post-title {
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    color: white;
    background-color: rgba(0, 0, 0, 0.7); /* Ensure text background is slightly transparent */
    text-align: center;
    padding: 2px;
    font-size: 1em;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.post-title a {
    text-decoration: none;
    color: white;
}

.post-title a:hover {
    text-decoration: underline;
    color: yellow;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .post {
        width: 150px;
        height: 150px;
        max-width: 150px;
        max-height: 150px;
    }

    .posts-container {
        padding: 5px 0;
    }

    .post-title {
        font-size: 0.8em;
    }
}

/* Further adjustments for smaller devices */
@media (max-width: 480px) {
    .post {
        width: 120px;
        height: 120px;
        max-width: 120px;
        max-height: 120px;
    }

    .posts-container {
        padding: 5px 0;
    }

    .post-title {
        font-size: 0.6em;
    }
}

/* iPhone X Specific Media Queries */
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
    .post {
        width: 150px;
        height: 150px;
    }

    .posts-container {
        padding: 5px 0;
    }

    .post-title {
        font-size: 0.8em;
    }
}

@media only screen and (min-width: 375px) and (max-height: 812px) and (orientation: landscape) {
    .post {
        width: 120px;
        height: 120px;
    }

    .posts-container {
        padding: 5px 0;
    }

    .post-title {
        font-size: 0.6em;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3), 
       only screen and (min--moz-device-pixel-ratio: 3), 
       only screen and (-o-min-device-pixel-ratio: 3/1), 
       only screen and (min-device-pixel-ratio: 3) {
    .post {
        width: 150px;
        height: 150px;
    }

    .posts-container {
        padding: 5px 0;
    }

    .post-title {
        font-size: 0.8em;
    }
}
